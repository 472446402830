import React, { useState, useEffect } from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { motion } from "framer-motion";

import "./Home.css";

import { Col, Container, Row } from "react-bootstrap";
import bgimage from "../../assets/background/active-garage-bg.png";
import * as Icons from 'lucide-react';
import { api } from "../../utils/api";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Car,
  CarFront,
  Clock,
  Paintbrush,
  PencilRuler,
  Settings,
  Shield,
  Wrench,
  Zap,
} from "lucide-react";
import { Users, Award, TrendingUp, Clock24 } from "lucide-react";
// import { TypeAnimation } from "react-type-animation";
import diagnosticsImage1 from "../../assets/vehicle-diagnostics/GRD_7944.jpg";
import diagnosticsImage2 from "../../assets/vehicle-diagnostics/GRD_7939.jpg";
import diagnosticsImage3 from "../../assets/vehicle-diagnostics/GRD_7948.jpg";
import diagnosticsImage4 from "../../assets/vehicle-diagnostics/GRD_7956.jpg";

import Slider from "react-slick";

import chassisProtection from "../../assets/chassis-protection/GRD_8025.jpg";
import wheelBalancingAlignment from "../../assets/wheel-alignments/GRD_8074.jpg";
import tyreChange from "../../assets/Tyre/GRD_7928.jpg";
import carWash from "../../assets/carwash/carwash.jpg";
import repairsMaintenance from "../../assets/vehicle-diagnostics/GRD_7944.jpg";
import electricalWorks from "../../assets/eletrical/eletrical.jpg";
import engineOverhaul from "../../assets/Engine/GRD_7981.jpg";
import { getServices } from "../../utils/api";
import QuoteForm from "../QuoteForm/QuoteForm";
import '../navbar/NavbarStyles.css'
// Add this after your imports to verify the images are imported correctly
console.log("Diagnostic Images:", {
  diagnosticsImage1,
  diagnosticsImage2,
  diagnosticsImage3,
  diagnosticsImage4,
});

function Home() {
  // Add state for mobile menu
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isQuoteFormOpen, setIsQuoteFormOpen] = useState(false);
  const [services, setServices] = useState([]);
  const [heroData, setHeroData] = useState({
    background_image: '',
    description: ''
  });
  const [stats, setStats] = useState({
    satisfiedClients: 500,
    yearsExperience: 7
  });
  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await api.get('/why-choose-us/');
        setStats({
          satisfiedClients: response.data.satisfied_clients,
          yearsExperience: response.data.years_experience
        });
      } catch (error) {
        console.error('Error fetching stats:', error);
      }
    };

    fetchStats();
  }, []);

  const statsList = [
    {
      icon: <Users />,
      title: "Satisfied Clients",
      count: `${stats.satisfiedClients}+`,
      description: "Trust us with their business",
    },
    {
      icon: <Award />,
      title: "Years Experience",
      count: `${stats.yearsExperience}+`,
      description: "Of industry expertise",
    },
    {
      icon: <TrendingUp />,
      title: "Success Rate",
      count: "100%",
      description: "Work completion rate",
    },
    {
      icon: <Clock />,
      title: "Support",
      count: "24/7",
      description: "Always here to help",
    },
  ];

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Add the debug useEffect here if you still need it
  useEffect(() => {
    // Debug log to check image paths
    console.log("Checking image paths:", {
      diagnosticsImage1,
      diagnosticsImage2,
      diagnosticsImage3,
      diagnosticsImage4,
    });


    // Preload images to check if they load correctly
    const preloadImages = [
      diagnosticsImage1,
      diagnosticsImage2,
      diagnosticsImage3,
      diagnosticsImage4,
    ];

    preloadImages.forEach((src, index) => {
      const img = new Image();
      img.src = src;
      img.onload = () => console.log(`Image ${index + 1} loaded successfully`);
      img.onerror = () => console.error(`Image ${index + 1} failed to load`);
    });
  }, []);

  useEffect(() => {
    const fetchHeroData = async () => {
      try {
        const response = await api.get('/hero-section/');
        setHeroData(response.data);
      } catch (error) {
        console.error('Error fetching hero data:', error);
      }
    };

    fetchHeroData();
  }, []);

  useEffect(() => {
    const fetchServices = async () => {
      const data = await getServices();
      setServices(data);
    };
    fetchServices();
  }, []);

  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth }}
    >
      <div className="home-container">
        {/* Hero Section */}
        <div
          className="hero"
          style={{
            backgroundImage: `url(${heroData.background_image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
            width: "100%",
            height: "100vh",
          }}
        >
          <div
            className="overlay10"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          ></div>

          <div
            className="hero-content"
            style={{
              position: "relative",
              zIndex: 2,
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
            }}
          >
            <motion.div
              className="hero-text-container"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
            >
              <motion.h1
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                className="hero-title"
              >
                <div className="title-row">
                  <motion.span
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.8, delay: 0.2 }}
                  >
                    QUALITY
                  </motion.span>
                </div>
                <div className="title-row">
                  <motion.span
                    className="highlight"
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.8, delay: 0.4 }}
                    whileHover={{ scale: 1.05 }}
                  >
                    EXPERTISE
                  </motion.span>
                  <span className="separator">|</span>
                  <motion.span
                    className="highlight"
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.8, delay: 0.6 }}
                    whileHover={{ scale: 1.05 }}
                  >
                    RELIABILITY
                  </motion.span>
                </div>
              </motion.h1>

              <motion.p
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.8 }}
                className="hero-subtitle"
              >
                <span className="emphasis"></span> {heroData.description}
              </motion.p>

              <motion.div
                className="hero-buttons"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 1 }}
              >
                <Link to="/contactus" className="cta-button primary">
                  Get Started
                  <motion.span
                    className="arrow"
                    initial={{ x: 0 }}
                    whileHover={{ x: 5 }}
                  >
                    →
                  </motion.span>
                </Link>
                <Link
                  to="/services"
                  className="cta-button secondary"
                  onMouseEnter={(e) => e.target.classList.add("button-hover")}
                  onMouseLeave={(e) =>
                    e.target.classList.remove("button-hover")
                  }
                >
                  Our Services
                  <motion.span
                    className="arrow"
                    initial={{ x: 0 }}
                    whileHover={{ x: 5 }}
                  >
                    →
                  </motion.span>
                </Link>
              </motion.div>
            </motion.div>
          </div>
        </div>

        {/* Stats Section - New */}
        <section className="stats-section">
          <Container>
            <motion.div
              className="section-header text-center mb-5"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              <h2 className="section-title">Why Choose Us</h2>
              <p className="section-subtitle">
                Our commitment to excellence sets us apart
              </p>
            </motion.div>
            <Row className="features-row justify-content-center align-items-center g-4">
              {statsList.map((stat, index) => (
                <Col
                  key={index}
                  xs={12}
                  sm={6}
                  md={3}
                  className="feature-col text-center d-flex justify-content-center"
                >
                  <motion.div
                    className="feature-card"
                    initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{
                      delay: index * 0.2,
                      duration: 0.5,
                      ease: "easeOut",
                    }}
                    whileHover={{
                      scale: 1.05,
                      transition: { duration: 0.2 },
                    }}
                  >
                    <div className="feature-icon-wrapper">
                      {React.cloneElement(stat.icon, {
                        size: 40,
                        strokeWidth: 1.5,
                        className: "gradient-icon",
                      })}
                    </div>
                    <motion.h3
                      className="feature-count"
                      initial={{ opacity: 0, scale: 0.5 }}
                      whileInView={{ opacity: 1, scale: 1 }}
                      viewport={{ once: true }}
                      transition={{ delay: index * 0.2 + 0.3 }}
                    >
                      {stat.count}
                    </motion.h3>
                    <h4 className="feature-title">{stat.title}</h4>
                    <p className="feature-description">{stat.description}</p>
                  </motion.div>
                </Col>
              ))}
            </Row>
          </Container>
        </section>

        {/* Services Section */}
        <section className="services-section section-spacing">
          <div className="container">
            <motion.div
              className="section-header"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              <h2 className="section-title">Our Services</h2>
              <p className="section-subtitle">
                Comprehensive Business Solutions Tailored to Your Needs
              </p>
            </motion.div>
            <div className="services-grid">
              {services.map((service, index) => {
                return (
                  <motion.div
                    key={service.slug}
                    className="service-card"
                    style={{
                      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${service.bg_image})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                    whileHover={{
                      scale: 1.05,
                      boxShadow: "0 10px 20px rgba(0,0,0,0.1)",
                    }}
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ delay: index * 0.1 }}
                  >
                    <div className="service-content">
                      <h3>{service.title}</h3>
                      <p>{service.description}</p>
                    </div>
                    <Link to={`/services/${service.slug}`} className="learn-more">
                      Learn More →
                    </Link>
                  </motion.div>
                );
              })}
            </div>
          </div>
        </section>

        {/* Banner Section */}
        <section
          className="full-width-image-section"
          style={{
            width: "100%",
            height: "500px",
            backgroundImage: `url(${bgimage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundAttachment: "fixed",
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            className="overlay"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.6)",
            }}
          ></div>

          <motion.div
            className="banner-content"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            style={{
              position: "relative",
              zIndex: 2,
              textAlign: "center",
              color: "white",
              padding: "2rem",
            }}
          >
            <h2
              style={{
                fontSize: "2.5rem",
                fontWeight: "bold",
                marginBottom: "1.5rem",
                textTransform: "uppercase",
                letterSpacing: "2px",
              }}
            >
              Experience Excellence in Auto Care
            </h2>
            <p
              style={{
                fontSize: "1.2rem",
                maxWidth: "800px",
                margin: "0 auto 2rem",
                lineHeight: 1.6,
              }}
            >
              Your vehicle deserves the best care. Schedule a service today and
              join our family of satisfied customers.
            </p>
            <Link
              // Instead of navigating, open the quote form
              onClick={(e) => {
                e.preventDefault(); // Prevent default link behavior
                setIsQuoteFormOpen(true);
              }}
              to="#" // or remove 'to' prop
              className="cta-button primary"
              style={{
                padding: "1rem 2.5rem",
                fontSize: "1.1rem",
                borderRadius: "30px",
                textTransform: "uppercase",
                letterSpacing: "1px",
              }}
            >
              Book an Appointment
              <motion.span
                className="arrow"
                initial={{ x: 0 }}
                whileHover={{ x: 5 }}
                style={{ marginLeft: "10px" }}
              >
                →
              </motion.span>
            </Link>

          </motion.div>
        </section>
        {/* Vehicle Diagnostics Section */}
        <section className="diagnostics-section section-spacing">
          <Container>
            <div className="diagnostics-container">
              <h2
                className="section-title"
                style={{
                  color: "rgb(128, 0, 0)",
                  fontSize: "2.5rem",
                  fontWeight: "700",
                  marginTop: "0.5rem",
                  marginBottom: "1.5rem",
                }}
              >
                Vehicle Diagnostics, Repairs and Maintenance Services
              </h2>
              <motion.span
                className="section-subtitle"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                style={{
                  color: "rgb(128, 0, 0)",
                  fontSize: "1.1rem",
                  fontWeight: "600",
                  letterSpacing: "1px",
                  textTransform: "uppercase",
                }}
              >
                Expert Auto Care
              </motion.span>
              <Row className="align-items-center">
                <Col lg={6}>
                  <motion.div
                    className="diagnostics-content"
                    initial={{ opacity: 0, x: -50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                  >
                    <div
                      className="content-divider"
                      style={{
                        width: "80px",
                        height: "4px",
                        background: "rgb(128, 0, 0)",
                        marginBottom: "1.5rem",
                      }}
                    ></div>
                    <p
                      className="section-description"
                      style={{
                        fontSize: "1.1rem",
                        lineHeight: "1.8",
                        color: "#333",
                        marginBottom: "1.5rem",
                      }}
                    >
                 A vehicle service center is like a health clinic. We provide comprehensive vehicle diagnostics, repair, and maintenance services designed to keep your vehicle running smoothly and safely. Whether you're experiencing a specific issue or simply want to ensure your car is in top condition, our experienced team is here to help.
                    </p>
                    <p
                      className="section-description highlight-text"
                      style={{
                        fontSize: "1.1rem",
                        lineHeight: "1.8",
                        color: "rgb(128, 0, 0)",
                        fontWeight: "600",
                        padding: "1rem",
                        borderLeft: "4px solid rgb(128, 0, 0)",
                        backgroundColor: "rgba(128, 0, 0, 0.05)",
                        marginBottom: "2rem",
                      }}
                    >
                      At Active Garage we have hand-picked professionals and an
                      array of state-of-the-art equipment for checking all
                      aspects for all kinds of vehicles.
                    </p>
                    <motion.div
                      className="services-list"
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      viewport={{ once: true }}
                      transition={{ delay: 0.2 }}
                    >
                      {[
                        "Multi-point Engine Check-up",
                        "Engine & Gearbox Oil Change",
                        "Brake Service & Cleaning",
                        "AC Repair & Gas Refill",
                      ].map((service, index) => (
                        <motion.div
                          key={index}
                          className="service-item"
                          initial={{ opacity: 0, x: -20 }}
                          whileInView={{ opacity: 1, x: 0 }}
                          viewport={{ once: true }}
                          transition={{ delay: index * 0.1 }}
                        >
                          <span className="service-icon">✓</span>
                          <span className="service-text">{service}</span>
                        </motion.div>
                      ))}
                    </motion.div>
                    {/* View More Button */}
                    <motion.div
                      className="text-center"
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                    >
                      <Link
                        to="/services"
                        className="cta-button secondary"
                        onMouseEnter={(e) =>
                          e.target.classList.add("button-hover")
                        }
                        onMouseLeave={(e) =>
                          e.target.classList.remove("button-hover")
                        }
                      >
                        View More →
                      </Link>
                    </motion.div>
                  </motion.div>
                </Col>
                <Col lg={6}>
                  <motion.div
                    className="diagnostics-slider-wrapper"
                    initial={{ opacity: 0, x: 50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    style={{
                      maxWidth: "100%",
                      overflow: "hidden",
                      margin: "20px 0",
                      borderRadius: "8px",
                      height: "500px",
                    }}
                  >
                    <Slider
                      dots={true}
                      infinite={true}
                      speed={1200}
                      slidesToShow={1}
                      slidesToScroll={1}
                      autoplay={true}
                      autoplaySpeed={4000}
                      arrows={true}
                      className="diagnostics-slider"
                      pauseOnHover={false}
                      cssEase="linear"
                      fade={true}
                      adaptiveHeight={false}
                    >
                      {[
                        {
                          img: diagnosticsImage1,
                          alt: "Vehicle Diagnostics Service",
                        },
                        {
                          img: diagnosticsImage2,
                          alt: "Car Maintenance",
                        },
                        {
                          img: diagnosticsImage3,
                          alt: "Auto Repair",
                        },
                        {
                          img: diagnosticsImage4,
                          alt: "Auto Service",
                        },
                      ].map((slide, index) => (
                        <div
                          key={index}
                          className="slider-item"
                          style={{
                            width: "100%",
                            height: "500px", // Match wrapper height
                            position: "relative",
                            backgroundColor: "#f5f5f5", // Fallback color
                          }}
                        >
                          <img
                            src={slide.img}
                            alt={slide.alt}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              display: "block",
                              position: "absolute",
                              top: 0,
                              left: 0,
                            }}
                            onLoad={(e) => {
                              console.log(
                                `Image ${index + 1} rendered successfully`
                              );
                              e.target.style.opacity = 1;
                            }}
                            onError={(e) => {
                              console.error(`Error loading image ${index + 1}`);
                              e.target.src =
                                "https://via.placeholder.com/600x400?text=Image+Not+Found";
                            }}
                          />
                        </div>
                      ))}
                    </Slider>
                  </motion.div>
                </Col>
              </Row>
            </div>
          </Container>
        </section>

        <div>   
          <QuoteForm
          isOpen={isQuoteFormOpen}
          onClose={() => setIsQuoteFormOpen(false)}
        /></div>
      </div>
    </motion.div>
  );
}

export default Home;
